<template>
  <v-container fluid class="pt-0">
    <div class="text-h5">库存列表</div>
    <div class="mt-1 mb-5" style="border-bottom: 1px solid #ddd;"></div>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
            dense
            label="输入书名查询"
            solo
            @input="changeSearch"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-btn color="primary lighten-3" class="mr-5" @click="addItem">新增</v-btn>
        <v-btn color="primary" @click="query">查询</v-btn>
      </v-col>
    </v-row>
    <v-card flat min-height="300" tile>
<!--      <v-card-title>-->
<!--        <v-text-field-->
<!--            v-model="search"-->
<!--            append-icon="mdi-magnify"-->
<!--            label="Search"-->
<!--            single-line-->
<!--            hide-details-->
<!--        ></v-text-field>-->
<!--      </v-card-title>-->
      <v-data-table
          :headers="headers"
          :items="desserts"
      >
        <template v-slot:item.book="{item}">
          {{item.book&&item.book.book_number}}
        </template>
        <template v-slot:item.update_time="{ item }">
          {{ item.update_time | dateFormat('YYYY-MM-DD HH:mm') }}
        </template>
        <template v-slot:item.action="{item}">
          <v-btn color="primary" class="mr-1" small @click="editItem(item.id)">编辑</v-btn>
          <v-btn color="error" small @click="deleteItem(item.id)">删除</v-btn>
        </template>
      </v-data-table>
    </v-card>
    <div v-if="refresh" class="pagination">
      <v-pagination
          v-if="desserts.length > 0"
          v-model="search.page"
          :length="pageCount"
          @input="(e) => doGet(e)"
      ></v-pagination>
    </div>
    <v-dialog v-model="dialog" width="800px">
      <v-card>
        <v-card-title>删除库存</v-card-title>
        <v-card-text>
          请问是否要删除该库存？
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog=false">取消</v-btn>
          <v-btn @click="takeDown">确认</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {get_list,del} from "../../api/inventory";
import _ from "lodash";

export default {
  name: "index",
  data() {
    return {
      refresh: true,
      search: {
        page: 1,
        pagesize: 10,
        search:'',
      },
      total: 0,
      headers: [
        {
          text: '编号',
          align: 'start',
          filterable: true,
          value: 'id',
        },
        {
          text: '书籍编号',
          value: 'book'
        },
        {
          text: '说明',
          value: 'description'
        },
        {text: '库存变化', value: 'count'},
        {text: '单价', value: 'price'},
        {text: '时间', value: 'update_time'},
        {text: '操作', value: 'action'},
      ],
      desserts: [

      ],
      dialog:false,
      id:''
    }
  },
  computed: {
    pageCount() {
      return Math.ceil(this.total / this.search.pagesize);
    },
  },
  mounted() {
    this.doGet()
  },
  methods:{
    async doGet(page = this.search.page) {
      const {results,count}=await get_list({ ...this.search, page })
      this.desserts = results;
      this.total = count;
      this.refresh = false;
      this.$nextTick(() => {
        this.refresh = true;
      });
      console.log(this.desserts)
    },
    changeSearch: _.debounce(function (e) {
      this.search.search=e
      this.search.page=1
      this.doGet()
    }, 500),

    editItem(id) {
      this.$router.push({ name: "edit_inventory", params: { id } });
    },
    async deleteItem(id){
      this.id=id
      this.dialog=true

    },
    async takeDown(){
      const data= await del(this.id)
      console.log(data)
      this.search.page!==1&&this.total - 1 >= this.search.pagesize && (this.total) % this.search.pagesize === 1 && (this.search.page = this.search.page - 1);
      await this.doGet()
      this.$snackbar({
        content: '已删除',
        color: 'success',
        showClose: true,
      })
      this.dialog=false
    },
    addItem(){
      this.$router.push({ name: "add_inventory"});
    },
    query(){
      this.search.page=1
      this.doGet()
    },
  }
}
</script>

<style scoped>

</style>